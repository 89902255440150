.comunidad-container {
    display: flex;
    flex-direction: column;
    font-family: Arial, sans-serif;
    padding: 1rem;
    margin-top: -1rem;
    min-height: 100vh;
    box-sizing: border-box;
    overflow-y: auto; /* Agrega desplazamiento vertical */
  }

  .comunidad-main {
    display: flex;
    flex-direction: row;
    margin: -1rem;
    margin-bottom: 1rem;
    gap: 2rem;
  }

  .comunidad-parrafo {
    display: flex;
    flex-direction: row;
    margin-left: 3rem;
    margin-right: 3rem;
    gap: 2rem;
  }

  .comunidad-mision-vision {
    display: grid;
    grid-template-columns: 1fr 1fr;
    flex-direction: row;
    margin-left: 3rem;
    margin-right: 3rem;
    gap: 2rem;
  }

  .comunidad-mision-vision h5{
    font-size: 18px;
    text-align: justify;
    font-weight: normal;
    margin-bottom:10px;
    margin-top: 10px;
  }

  .comunidad-parrafo h5{
    font-size: 18px;
    text-align: justify;
    font-weight: normal;
    margin-bottom:10px;
    margin-top: 10px;
  }

  .comunidad-main img{
    width: 15rem;
  }

  .comunidad-descripcion{
    display:flex;
    flex-direction: column;
    width: 55rem;
  }

  .comunidad-descripcion h1{
    margin: 0;
  }

  .comunidad-descripcion h5{
    font-size: 18px;
    text-align: justify;
    font-weight: normal;
    margin-bottom:10px;
    margin-top: 10px;
  }

  .comunidad-integrantes{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 37rem;
  }

  .comunidad-integrantes img{
    width: 45rem;
    height: 30rem;
  }

  .comunidad-parrafo2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-left: 3rem;
    margin-right: 3rem;
    gap: 2rem;
  }

  .comunidad-parrafo3 {
    display: flex;
    flex-direction: column;
  }

  .comunidad-parrafo4 {
    display: flex;
    flex-direction: row;
    align-items: left;
    margin: 0;
    margin-bottom: 1rem;
  }

  .comunidad-parrafo4 h3{
    width: 10rem;
    text-align: left;
    margin: 0;
    font-size: 18px;
    font-weight: bold;
  }

  .comunidad-parrafo4 h5{
    text-align: left;
    margin: 0;
    font-weight: normal;
    font-size: 18px;
  }

  .comunidad-parrafo5 {
    display: flex;
    flex-direction: column;
  }

  .comunidad-parrafo5 h5{
    text-align: left;
    margin: 0.1rem;
    font-weight: normal;
    font-size: 18px;
  }

  /* Para dispositivos móviles (pantallas de hasta 600px) */
@media only screen and (max-width: 600px) {
  .comunidad-container {
    padding: 0.5rem;
    margin-top: -2rem;
  }

  .comunidad-main {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin: 0;
    padding: 1rem;
  }

  .comunidad-parrafo {
    flex-direction: column;
    margin: 0;
    gap: 1rem;
    padding: 1rem;
  }

  .comunidad-mision-vision {
    grid-template-columns: 1fr; /* Una sola columna */
    margin: 0;
    gap: 1rem;
    padding: 1rem;
  }

  .comunidad-main img {
    width: 12rem; /* Reduce el tamaño de la imagen */
  }

  .comunidad-descripcion {
    width: 100%; /* Ocupa todo el ancho */
  }

  .comunidad-descripcion h1 {
    font-size: 1.5rem; /* Ajusta el tamaño del título */
  }

  .comunidad-integrantes {
    height: auto;
    width: 95%; /* Asegura que ocupe todo el ancho */
  }

  .comunidad-integrantes img {
    width: 100%; /* Ajusta al ancho disponible */
    height: auto; /* Mantén la proporción */
  }

  .comunidad-parrafo2 {
    grid-template-columns: 1fr; /* Una sola columna */
    margin: 0;
  }

  .comunidad-parrafo3,
  .comunidad-parrafo4,
  .comunidad-parrafo5 {
    flex-direction: column; /* Cambia a disposición en columna */
    margin: 0;
  }

  .comunidad-parrafo4 h3 {
    font-size: 1rem; /* Ajusta el tamaño del texto */
    margin: 0.5rem 0;
  }

  .comunidad-parrafo4 h5 {
    font-size: 0.9rem;
  }

  .comunidad-parrafo5 h5 {
    font-size: 0.9rem;
    margin: 0.5rem 0;
  }
}

/* Para tablets (pantallas entre 601px y 1080px) */
@media only screen and (min-width: 601px) and (max-width: 1080px) {
  .comunidad-container {
    padding: 1rem;
    margin-top: 0;
  }

  .comunidad-main {
    flex-direction: column; /* Coloca los elementos en columna */
    align-items: center;
    gap: 1.5rem;
    margin: 0;
  }

  .comunidad-parrafo {
    flex-direction: column; /* Cambia a columna */
    margin: 0 1rem;
    gap: 1rem;
  }

  .comunidad-mision-vision {
    grid-template-columns: 1fr; /* Una sola columna */
    gap: 1rem;
    margin: 0 1rem;
  }

  .comunidad-main img {
    width: 14rem; /* Ajusta el tamaño de la imagen */
  }

  .comunidad-descripcion {
    width: 90%; /* Ocupa el 90% del ancho */
  }

  .comunidad-descripcion h1 {
    font-size: 2rem; /* Tamaño intermedio */
  }

  .comunidad-integrantes {
    width: 100%;
    height: auto; /* Ajusta automáticamente */
  }

  .comunidad-integrantes img {
    width: 100%; /* Asegura que ocupe todo el ancho */
    height: auto; /* Mantiene proporción */
  }

  .comunidad-parrafo2 {
    grid-template-columns: 1fr; /* Cambia a una columna */
    margin: 0;
    gap: 1rem;
  }

  .comunidad-parrafo3,
  .comunidad-parrafo4,
  .comunidad-parrafo5 {
    flex-direction: column;
    margin: 0;
  }

  .comunidad-parrafo4 h3 {
    font-size: 1.2rem; /* Ajusta tamaño del texto */
    margin: 0.5rem 0;
  }

  .comunidad-parrafo4 h5 {
    font-size: 1rem;
  }

  .comunidad-parrafo5 h5 {
    font-size: 1rem;
    margin: 0.5rem 0;
  }
}

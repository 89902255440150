.enciclopedia-container {
  display: flex;
  flex-direction: column;
  font-family: Arial, sans-serif;
  padding: 1rem;
  margin-top: -4rem;
  min-height: 100vh;
  box-sizing: border-box;
  overflow: hidden;
}

.enciclopedia-main {
  display: grid;
  grid-template-columns: 1fr 1fr 3fr;
  margin-top: -1rem;
  margin-bottom: 0;
  gap: 1rem;
}

.enciclopedia-titulos {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  height: 29rem;
  border-radius: 10px;
  width: 14rem;
  padding: 10px;
  text-align: left;
}

.enciclopedia-subtitulos {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  height: 29rem;
  border-radius: 10px;
  width: 14rem;
  padding: 10px;
  text-align: left;
}

.enciclopedia-contenidos {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  height: 30rem;
  border-radius: 10px;
  width: auto;
  gap: 0rem;
}

.enciclopedia-contenidos h2{
  margin:0.7rem;
}

.enciclopedia-buscar {
  display: flex;
  flex-direction: column;
  gap: 0;
}

.enciclopedia-buscar h2 {
  margin: 0;
}

.enciclopedia-buscar h3 {
  margin-bottom: 5px;
  margin-top: 0.5rem;
}

.enciclopedia-buscar input {
  height: 1.3rem;
  font-size: 18px;
  padding: 5px;
}

.enciclopedia-listbox-container {
  height: 21.5rem;
  overflow-y: auto;
  margin-top: 0.5rem;
  padding: 0.5rem;
}

.enciclopedia-listbox {
  list-style: none;
  padding: 0;
  margin: 0;
}

.enciclopedia-listbox-item {
  padding: 0.5rem;
  cursor: pointer;
}

.enciclopedia-listbox-item:hover {
  background-color: #6e44ff;
  color: white;
}

.enciclopedia-article-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  padding: 1rem;
  width: 100%; /* Se asegura de que ocupe todo el ancho del contenedor */
  box-sizing: border-box;
  height: 26.5rem;
  overflow-y:auto;
}


.enciclopedia-article-card {
  border: 1px solid #979797;
  border-radius: 10px;
  padding: 1rem;
  background-color: #eddaff;;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.2s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 10rem;
  width: 10rem;
  gap:2px;
}

.enciclopedia-article-card:hover {
  transform: scale(1.05);
}

.enciclopedia-article-card h3 {
  margin: 0 0 0.2rem;
  font-size: 18px;
  color: #333;
  overflow: hidden;
}

.enciclopedia-article-card p {
  margin: 0.5rem 0;
  font-size: 14px;
  color: #666;
}

.enciclopedia-article-card button {
  padding: 0.5rem 1rem;
  background-color: #6e44ff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.enciclopedia-article-card button:hover {
  background-color: #6e44ff;
  scale: 1.1;
}

.enciclopedia-article-content {
  padding: 1rem;
  overflow-y: auto;
  height: 100%;
}

.enciclopedia-article-content h2 {
  margin-bottom: 0.5rem;
}

.enciclopedia-article-content h4 {
  margin-bottom: 1rem;
  color: #666;
}

.enciclopedia-article-content p {
  font-size: 16px;
  line-height: 1.5;
  text-align: justify;
}

.enciclopedia-article-content button {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #6e44ff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.enciclopedia-article-content button:hover {
  background-color: #5a33d6;
}

.enciclopedia-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #6e44ff;
  font-weight: bold;
  height: 100%;
}

.enciclopedia-listbox-item.active {
  background-color: #6e44ff;
  color: white;
  font-weight: bold;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  
}

.modal {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 70rem;
  height: 33rem;
  overflow-y: auto;
}

.modal h2 {
  margin-top: 0;
}

.modal textarea {
  width: 100%;
  height: 25rem;
  resize: none;
  margin-top: 10px;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 10px;
}

.save-button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.save-button:hover {
  background-color: #45a049;
}

.cancel-button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.cancel-button:hover {
  background-color: #e53935;
}

textarea {
  width: 100%;
  height: 200px;
  font-family: monospace; /* Mejor para ver etiquetas HTML */
  font-size: 14px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.contenteditable-container {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  min-height: 200px;
  overflow-y: auto;
  background-color: #f9f9f9;
  font-family: Arial, sans-serif;
  font-size: 14px;
}

.modal-toolbar {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.toggle-view-button {
  background-color: #6e44ff;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.toggle-view-button:hover {
  background-color: #5a33d6;
}

.contenteditable-container {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  min-height: 200px;
  overflow-y: auto;
  background-color: #f9f9f9;
  font-family: Arial, sans-serif;
  font-size: 14px;
  white-space: pre-wrap;
  text-align: justify;
}

/* Dispositivos móviles (pantallas de hasta 600px) */
@media only screen and (max-width: 600px) {
  .enciclopedia-container {
    padding: 0.5rem;
    margin-top: -3rem;
  }

  .enciclopedia-main {
    grid-template-columns: 1fr; /* Cambia a una sola columna */
    gap: 1rem;
    width: 22rem;
  }

  .enciclopedia-titulos,
  .enciclopedia-subtitulos {
    width: 95%; /* Ocupa todo el ancho disponible */
    height: auto;
  }

  .enciclopedia-contenidos {
    width: 95%; /* Ajusta al ancho del contenedor */
    height: auto; /* Ajusta la altura automáticamente */
    padding: 0.5rem;
  }

  .enciclopedia-article-grid {
    grid-template-columns: 1fr; /* Cambia a una sola columna */
    height: auto;
    gap: 0.5rem;
  }

  .enciclopedia-article-card {
    height: auto; /* Ajusta automáticamente la altura */
    width: 100%; /* Usa todo el ancho disponible */
    padding: 0.5rem;
  }

  .modal {
    width: 90%; /* Reduce el ancho del modal */
    height: auto; /* Ajusta automáticamente la altura */
    padding: 1rem;
  }

  .modal textarea {
    height: 15rem; /* Reduce la altura del textarea */
  }

  .contenteditable-container {
    min-height: 10rem; /* Reduce la altura mínima */
  }

  .modal-buttons {
    flex-direction: column; /* Cambia la disposición a columna */
    gap: 0.5rem;
  }
}

/* Tablets (pantallas entre 601px y 1080px) */
@media only screen and (min-width: 601px) and (max-width: 1080px) {
  .enciclopedia-container {
    padding: 1rem;
    margin-top: -3rem;
  }

  .enciclopedia-main {
    grid-template-columns: 1fr 1fr 2fr; /* Cambia a dos columnas */
    gap: 1rem;
  }

  .enciclopedia-titulos,
  .enciclopedia-subtitulos {
    width: 10rem; /* Ajusta al ancho del contenedor */
    height: 29rem; /* Ajusta automáticamente la altura */
  }

  .enciclopedia-contenidos {
    width: 16rem; /* Usa todo el ancho disponible */
    height: 28rem; /* Ajusta la altura automáticamente */
    padding: 1rem;
  }

  .enciclopedia-article-grid {
    grid-template-columns: 1fr; /* Cambia a dos columnas */
    gap: 1rem;
    height: auto;
    overflow-y: auto;
  }

  .enciclopedia-article-card {
    height: auto; /* Ajusta automáticamente la altura */
    width: 12rem; /* Usa todo el ancho disponible */
    padding: 1rem;
  }

  .modal {
    width: 80%; /* Ajusta el ancho del modal */
    height: auto; /* Ajusta automáticamente la altura */
    padding: 1.5rem;
  }

  .modal textarea {
    height: 20rem; /* Ajusta la altura del textarea */
  }

  .contenteditable-container {
    min-height: 15rem; /* Ajusta la altura mínima */
  }

  .modal-buttons {
    flex-direction: row; /* Mantén la disposición en fila */
    gap: 1rem;
  }
}

/* src/design/Searcher.css */

:root {
    --primary-color: #6e44ff; /* Color principal para el botón y el título */
    --input-background: #f0f0f0; /* Fondo del campo de búsqueda */
    --border-color: #ccc; /* Color de borde del campo de búsqueda */
    --text-color: #4a4a4a; /* Color del texto */
  }
  
  .searcher-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    font-family: Arial, sans-serif;
  }
  
  .searcher-title {
    font-size: 2.2rem;
    color: var(--primary-color);
    font-weight: 500;
    margin-bottom: 2rem;
  }
  
  .search-bar {
    display: flex;
    align-items: center;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    overflow: hidden;
    width: 35rem;
    margin-bottom: 4rem;
  }
  
  .search-input {
    width: 100%;
    padding: 0.75rem;
    font-size: 1.5rem;
    border: none;
    outline: none;
    background-color: var(--input-background);
    color: black;
  }
  
  .search-input:hover {
    background-color: white;
    border: 1px solid black;
  }
  
  .search-input:focus {
    background-color: white;
    border: 1px solid black;
  }
  
  .search-button {
    background-color: var(--primary-color);
    color: #ffffff;
    height: 100%;
    border: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
  }
  
  .language-selector {
    display: flex;
    align-items: center;
    margin-top: 1rem;
  }
  
  .select-dropdown {
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    background-color: #ffffff;
    color: black;   
    margin-right: 0.5rem;
    width: 10rem;
  }
  
  .switch-button {
    background-color: var(--primary-color);
    color: #ffffff;
    border: none;
    padding: 0.5rem;
    border-radius: 5px;
    cursor: pointer;
  }
  
  /* Ajuste de tamaño para los íconos de Material Icons */
  .search-button .material-icons,
  .switch-button .material-icons {
    font-size: 1.5rem;
  }
  
  /* Para dispositivos móviles (pantallas de hasta 600px) */
@media only screen and (max-width: 600px) {
  .searcher-container {
    padding: 1rem;
  }

  .searcher-title {
    font-size: 1.8rem; /* Reduce el tamaño del título */
    margin-bottom: 1.5rem; /* Ajusta el espaciado */
  }

  .search-bar {
    width: 90%; /* Ocupa el 90% del ancho disponible */
    margin-bottom: 2rem; /* Reduce el margen inferior */
  }

  .search-input {
    padding: 0.5rem; /* Reduce el padding */
    font-size: 1.2rem; /* Ajusta el tamaño de la fuente */
  }

  .search-button {
    padding: 0.4rem 0.8rem; /* Reduce el padding del botón */
    font-size: 0.9rem; /* Ajusta el tamaño de la fuente */
  }

  .language-selector {
    flex-direction: column; /* Coloca los elementos en columna */
    align-items: flex-start; /* Alinea los elementos al inicio */
    gap: 0.5rem; /* Añade un pequeño espacio entre elementos */
  }

  .select-dropdown {
    width: 100%; /* Ocupa todo el ancho disponible */
  }

  .switch-button {
    width: 100%; /* Botón de cambio ocupa todo el ancho */
    padding: 0.5rem; /* Mantiene un tamaño cómodo */
  }
}

/* Para tablets (pantallas entre 601px y 1080px) */
@media only screen and (min-width: 601px) and (max-width: 1080px) {
  .searcher-container {
    padding: 1.5rem;
  }

  .searcher-title {
    font-size: 2rem; /* Tamaño intermedio del título */
    margin-bottom: 1.8rem; /* Espaciado proporcional */
  }

  .search-bar {
    width: 70%; /* Ajusta el ancho del campo de búsqueda */
    margin-bottom: 3rem; /* Espaciado ajustado */
  }

  .search-input {
    padding: 0.6rem; /* Ajusta el padding */
    font-size: 1.3rem; /* Tamaño intermedio de fuente */
  }

  .search-button {
    padding: 0.5rem 1rem; /* Botón ligeramente más grande */
    font-size: 1rem; /* Fuente proporcional */
  }

  .language-selector {
    flex-direction: row; /* Mantiene la disposición en fila */
    align-items: center;
    gap: 1rem; /* Espaciado mayor entre elementos */
  }

  .select-dropdown {
    width: 12rem; /* Aumenta ligeramente el ancho */
  }

  .switch-button {
    padding: 0.6rem 1rem; /* Espaciado más generoso */
  }
}

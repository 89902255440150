.cubulco-container {
    display: flex;
    flex-direction: column;
    font-family: Arial, sans-serif;
    padding: 1rem;
    margin-top: -1rem;
    min-height: 100vh;
    box-sizing: border-box;
    overflow-y: auto; /* Agrega desplazamiento vertical */
  }

  .cubulco-main {
    display: flex;
    flex-direction: row;
    margin: -1rem;
    margin-bottom: 1rem;
    gap: 2rem;
  }

  .cubulco-main img{
    width: 15rem;
  }

  .cubulco-descripcion{
    display:flex;
    flex-direction: column;
    width: 55rem;
  }

  .cubulco-parrafo1{
    display: grid;
    grid-template-columns: 1fr 4fr;
    gap: 1rem;
    align-items: center;
  }

  .cubulco-descripcion h1{
    margin: 0;
  }

  .cubulco-descripcion h5{
    font-size: 18px;
    text-align: justify;
    font-weight: normal;
    margin-bottom:10px;
    margin-top: 10px;
  }

  .cubulco-integrantes{
    background-color: rgb(212, 212, 212);
    width: 100%;
    height: 18rem;
  }

  /* Para dispositivos móviles (pantallas de hasta 600px) */
@media only screen and (max-width: 600px) {
  .cubulco-container {
    padding: 0.5rem;
    margin-top: -3rem;
  }

  .cubulco-main {
    flex-direction: column; /* Cambia a disposición vertical */
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin: 0;
    padding:1rem;
  }

  .cubulco-main img {
    width: 10rem; /* Reduce el tamaño de la imagen */
  }

  .cubulco-descripcion {
    width: 100%; /* Ocupa todo el ancho */
  }

  .cubulco-descripcion h1 {
    font-size: 1.5rem; /* Ajusta el tamaño del título */
    text-align: center;
  }

  .cubulco-descripcion h5 {
    font-size: 1rem; /* Reduce el tamaño del texto */
    text-align: justify;
  }

  .cubulco-parrafo1 {
    grid-template-columns: 1fr; /* Cambia a una sola columna */
    gap: 0.5rem;
  }

  .cubulco-integrantes {
    height: auto; /* Ajusta la altura automáticamente */
    width: 100%; /* Ocupa todo el ancho */
    padding: 1rem; /* Agrega espacio interno */
    background-color: rgb(220, 220, 220); /* Mantiene el fondo */
  }
}

/* Para tablets (pantallas entre 601px y 1080px) */
@media only screen and (min-width: 601px) and (max-width: 1080px) {
  .cubulco-container {
    padding: 1rem;
    margin-top: 0;
  }

  .cubulco-main {
    flex-direction: column; /* Cambia a disposición vertical */
    align-items: center;
    gap: 1.5rem;
    margin: 0;
  }

  .cubulco-main img {
    width: 12rem; /* Ajusta el tamaño de la imagen */
  }

  .cubulco-descripcion {
    width: 90%; /* Ocupa el 90% del ancho */
  }

  .cubulco-descripcion h1 {
    font-size: 2rem; /* Tamaño intermedio */
    text-align: center;
  }

  .cubulco-descripcion h5 {
    font-size: 1.2rem; /* Ajusta el tamaño del texto */
    text-align: justify;
  }

  .cubulco-parrafo1 {
    grid-template-columns: 1fr 3fr; /* Ajusta proporciones */
    gap: 1rem;
  }

  .cubulco-integrantes {
    height: auto; /* Ajusta automáticamente la altura */
    width: 100%; /* Ocupa todo el ancho */
    padding: 1rem; /* Agrega espacio interno */
    background-color: rgb(220, 220, 220); /* Mantiene el fondo */
  }
}



/* Asegura que el contenedor principal ocupe toda la altura de la ventana */
.diccionario-container {
  display: flex;
  flex-direction: column;
  font-family: Arial, sans-serif;
  padding: 1rem;
  margin-top: -4rem;
  min-height: 100vh;
  box-sizing: border-box;
  overflow: hidden;
}

.diccionario-main {
  display: grid;
  grid-template-columns: 1fr 3fr;
  margin-top: -1rem;
  margin-bottom: 0;
}

.diccionario-body {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
}

.diccionario-search-bar {
  display: flex;
  padding: 0.5rem;
  border-radius: 5px;
  margin-bottom: 1.5rem;
  align-items: center;
  justify-content: left;
  width: 100%;
  padding: 0;
}

.diccionario-search-input {
  flex: 1;
  padding: 0.5rem;
  border: 1px solid black;
  border-radius: 5px;
  font-size: 1.2rem;
  outline: none;
  margin-right: 0.5rem;
}

.diccionario-select-dropdown {
  margin-right: 0.5rem;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #ffffff;
  color: #333;
}

.diccionario-search-button {
  background-color: #6e44ff;
  color: white;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border: 1px solid black;
  border-radius: 5px;
  font-size: 1.2rem;
  cursor: pointer;
  margin-right: 1rem;
}

.diccionario-search-button:hover {
  transform: scale(1.1);
}

.diccionario-content {
  display: flex;
  flex-grow: 1;
  margin-bottom: 0;
}

.diccionario-sidebar {
  width: 23rem;
  background-color: white;
  margin-right: 1rem;
  border-radius: 5px;
  border: 1px solid rgb(180, 180, 180);
  height: 26.6rem;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.diccionario-sidebar-section {
  display: grid;
  padding: 1rem;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 4px;
  background-color: #eddaff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.diccionario-list {
  flex-grow: 1;
  overflow-y: auto; 
}

.listbox-item {
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  text-align: left;
}

.listbox-item:hover {
  background-color: #6e44ff;
  color: white;
}

.diccionario-sidebar-section button {
  width: 2rem;
  border: none;
  font-size: 20px;
  font-weight: bolder;
  background-color: #eddaff;
}

.diccionario-sidebar-section button:hover {
  transform: scale(1.3);
  cursor: pointer;
}

.sidebar-button {
  width: 2rem;
  border: none;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
  background-color: #eddaff;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.sidebar-button.active {
  background-color: #6e44ff;
  color: white;
}

.sidebar-button:hover {
  background-color: #b3a2ff;
}


.diccionario-results {
  display: grid;
  grid-template-columns: 3fr 1fr;
  margin-bottom: 0;
}

.diccionario-main-content {
  height: 21rem;
  padding: 1rem;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border: 1px solid rgb(180, 180, 180);
  margin-top: -0.5rem;
  margin-bottom: 0;
}

.diccionario-word-section {
  display: flex;
  flex-direction: column;
  width: 35rem;
  justify-content: center;
  height: 100%;
  flex-grow: 1;
  margin-bottom: 0;
}

.diccionario-word-section .word {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  height: 2rem;
  padding-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.diccionario-italic {
  font-style: italic;
  color: #666;
  margin: 0;
}

.diccionario-audio-icon {
  font-size: 35px;
  color: #6e44ff;
  cursor: pointer;
}

.diccionario-audio-icon:hover {
  transform: scale(1.3);
}

.diccionario-word-section p {
  font-size: 23px;
  margin-top: 0;
  text-align: left;
  height: 10rem;
  flex-grow: 1;
  margin-bottom: 0;
}

.diccionario-word-section h3 {
  font-size: 15px;
  font-style: italic;
  text-align: left;
  margin: 0;
  align-self: flex-end;
  margin-bottom: 0;
}

.diccionario-divider {
  border: none;
  border-top: 1px solid #ccc;
  width: 100%;
}

.diccionario-word-of-the-day {
  background-color: #ffffff;
  padding: 1rem;
  margin-left: 1rem;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border: 1px solid rgb(180, 180, 180);
  margin-top: -0.5rem;
  margin-bottom: 0;
}

.diccionario-word-of-the-day h3 {
  font-size: 1rem;
  color: #333;
}

.diccionario-word-of-the-day p {
  font-size: 1rem;
  color: #666;
  margin: 0.5rem 0;
}

.diccionario-subscribe-button {
  background-color: #6e44ff;
  color: #ffffff;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 1rem;
  font-weight: bold;
}

.diccionario-subscribe-button:hover {
  scale:1.1;
}

.diccionario-facebook-icon {
  font-size: 5rem; /* Ajusta el tamaño del icono */
  color: #1877f2; /* Color azul típico de Facebook */
  margin: 0;
}

/* Dispositivos móviles (pantallas de hasta 600px) */
@media only screen and (max-width: 600px) {
  .diccionario-container {
    padding: 0.5rem;
    margin-top: -3rem;
  }

  .diccionario-main {
    grid-template-columns: 1fr; /* Cambia a una sola columna */
    gap: 1rem;
  }

  .diccionario-sidebar {
    width: 22rem; /* Ocupa todo el ancho disponible */
    position: static; /* Elimina la posición sticky */
    height: 25rem; /* Ajusta automáticamente la altura */
    margin-right: 0;
  }

  .diccionario-sidebar-section {
    grid-template-columns: repeat(9, 1fr); /* Muestra solo 4 botones por fila */
    gap: 5px;
  }

  .diccionario-list {
    height: auto;
  }

  .diccionario-body{
    width: 20rem;
  }

  .diccionario-results {
    grid-template-columns: 1fr; /* Cambia a una sola columna */
    gap: 1rem;
  }

  .diccionario-main-content,
  .diccionario-word-of-the-day {
    width: 100%; /* Usa todo el ancho disponible */
    margin: 0;
  }

  .diccionario-word-section {
    width: 100%; /* Ajusta el ancho al contenedor */
  }

  .diccionario-word-section .word {
    flex-direction: row; /* Cambia a disposición vertical */
    gap: 0.2rem;
    text-align: center;
  }

  .diccionario-word-section p {
    font-size: 18px; /* Ajusta el tamaño de la fuente */
  }

  .diccionario-word-of-the-day {
    margin-left: 0;
  }

  .diccionario-subscribe-button {
    width: 100%; /* Ocupa todo el ancho disponible */
    font-size: 1rem;
  }

  .diccionario-facebook-icon {
    font-size: 4rem; /* Reduce el tamaño del icono */
  }
}

/* Tablets (pantallas entre 601px y 1080px) */
@media only screen and (min-width: 601px) and (max-width: 1080px) {
  .diccionario-container {
    padding: 1rem;
  }

  .diccionario-main {
    grid-template-columns: 1fr 2fr; /* Ajusta las proporciones */
    gap: 1rem;
  }

  .diccionario-sidebar {
    width: 100%; /* Usa todo el ancho disponible */
    position: static;
    height: auto;
    margin-right: 0;
  }

  .diccionario-sidebar-section {
    grid-template-columns: repeat(6, 1fr); /* Muestra 6 botones por fila */
    gap: 8px;
  }

  .diccionario-results {
    grid-template-columns: 1fr; /* Cambia a una sola columna */
  }

  .diccionario-main-content,
  .diccionario-word-of-the-day {
    width: 100%;
    margin: 0;
  }

  .diccionario-word-section {
    width: 100%;
  }

  .diccionario-word-section .word {
    flex-direction: row;
    gap: 0.5rem;
  }

  .diccionario-word-section p {
    font-size: 20px; /* Ajusta el tamaño del texto */
  }

  .diccionario-subscribe-button {
    width: auto; /* Ajusta automáticamente el ancho */
  }

  .diccionario-facebook-icon {
    font-size: 4.5rem; /* Ajusta ligeramente el tamaño */
  }

  .diccionario-body{
    width: 24rem;
  }
}

/* src/Components/code/Home.css */

:root {
    --primary-color: #2a1a5e;        /* Color de fondo del encabezado */
    --secondary-color: #32264d;      /* Color para la barra de navegación secundaria */
    --accent-color: #ffffff;         /* Color del texto y logo */
    --search-background: #ffffff;    /* Fondo de la barra de búsqueda */
    --search-icon-color: #2a1a5e;    /* Color del icono de búsqueda */
    --slider-background: #e8eaf6;    /* Fondo del slider */
    --button-color: #6e44ff;         /* Color del botón en el slider */
  }
  
  .home-container {
    font-family: Arial, sans-serif;
  }
  
  /* Encabezado principal */
  .header {
    background-color: var(--primary-color);
    background-image: url('../../assets/cubulco.jpg'); /* Ruta de tu imagen de banner */
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.3rem 2rem;
    color: var(--accent-color);
  }
  
  .header-logo {
    font-size: 1.5rem;
    font-weight: bold;
    display: flex;
    align-items: center;
  }
  
  .header-logo img {
    height: 80px;
    margin-right: 0.5rem;
    border-radius: 50%;
    border: 2px solid black;
  }

  .header-logo span {
    font-size: 34px;
    font-weight: bold;
    color: var(--accent-color); /* Asegúrate de que el color contraste con la sombra */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.887); /* Ajusta los valores y color según tus necesidades */
  }
  
  /* Sub-encabezado */
  .sub-header {
    background-color: var(--secondary-color);
    padding: 0.5rem 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .sub-header-links {
    display: flex;
    gap: 1.5rem;
    justify-content: center;
  }
  
  .sub-header-link {
    color: var(--accent-color);
    text-decoration: none;
    font-size: 1rem;
  }

  .sub-header-link:hover {
    scale:1.1;
    font-style:initial;
    text-decoration: underline;
  }
  
  /* Slider */
  .body-page {
    display: flex;
    justify-content: center;
    /* background-color: var(--slider-background); */
    padding: 1rem;
    text-align: center;
    border-radius: 8px;
    margin: 2rem auto;
    width: 98%;
  }
  
  /* Para tablets (pantallas de 601px a 1080px) */
@media only screen and (min-width: 601px) and (max-width: 1080px) {
  .header {
    padding: 0.5rem 1rem; /* Reduce el padding del encabezado */
  }

  .header-logo img {
    height: 60px; /* Reduce el tamaño del logo */
    margin-right: 0.5rem;
  }

  .header-logo span {
    font-size: 28px; /* Ajusta el tamaño del texto del logo */
  }

  .sub-header {
    padding: 0.5rem 1rem; /* Reduce el padding del subencabezado */
    height: auto; /* Ajusta automáticamente la altura */
  }

  .sub-header-link {
    font-size: 0.9rem; /* Ajusta el tamaño de los enlaces */
  }

  .body-page {
    padding: 1rem; /* Mantén el padding general */
    margin: 1rem auto; /* Reduce el margen vertical */
    width: 90%; /* Ajusta el ancho del contenido */
  }
}

/* Para teléfonos (pantallas de hasta 600px) */
@media only screen and (max-width: 600px) {
  .header {
    flex-direction: column; /* Cambia a disposición vertical */
    text-align: center;
    padding: 0.5rem;
  }

  .header-logo {
    justify-content: center; /* Centra el logo */
  }

  .header-logo img {
    height: 50px; /* Reduce aún más el tamaño del logo */
  }

  .header-logo span {
    font-size: 24px; /* Ajusta el tamaño del texto del logo */
  }

  .sub-header {
    flex-direction: column; /* Cambia a disposición vertical */
    padding: 0.5rem;
    text-align: center;
    height: 4rem;
    margin-bottom: 2rem;
  }

  .sub-header-links {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 0.5rem; /* Reduce el espacio entre enlaces */
  }

  .sub-header-link {
    font-size: 0.8rem; /* Reduce el tamaño del texto */
  }

  .body-page {
    padding: 0.5rem; /* Reduce el padding */
    margin: 0.5rem auto; /* Reduce el margen vertical */
    width: 100%; /* Usa el ancho completo */
  }
}
